var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1b7040306ec619e59ea4a6049e86994b70a64601"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';
import getConfig from 'next/config';

import * as sentryHelpers from './src/shared/util/sentry-helpers';

const { publicRuntimeConfig = {} } = getConfig();
const { sentry = {}, environment } = publicRuntimeConfig;
const { dsn = {}, sendOnlyFromBundles } = sentry;

// app is not running via yarn dev, not jest tests & has dsn value in config
if (!__DEV__ && !__TEST__ && dsn.frontend) {
  Sentry.init({
    dsn: dsn.frontend,
    environment,
    integrations: [new BrowserTracing()],
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    tracesSampleRate: 0.001,

    // common errors & urls to filter - https://gist.github.com/impressiver/5092952
    // doc - https://docs.sentry.io/platforms/javascript/configuration/filtering/
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage'
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ],

    // doc - https://docs.sentry.io/platforms/javascript/configuration/options/#before-send
    beforeSend: (event = {}, hint = {}) => {
      event.extra = event.extra || {};
      event.tags = event.tags || {};
      event.tags.logger = event.tags.logger || '_default_';

      const isGTM = sentryHelpers.isGTMError(hint);
      const isFromBundle = sentryHelpers.isNextjsBundleError(event);
      if (isGTM) {
        event.tags.logger = 'gtm';
      } else if (isFromBundle) {
        event.tags.logger = 'nextjs-bundle';
      }

      // skip reporting the event which is not from Next.js bundles
      if (sendOnlyFromBundles && !isFromBundle) {
        return null;
      }

      return event;
    }
  });
}
